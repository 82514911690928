import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest xampinyó blanquinós és rabassut i globós primerament i, amb el temps s’enfonsa al centre. Té nombroses làmines, atapeïdes i lliures de color carn. El peu és blanc i rabassut amb fibril·les longitudinals. L’anell d’aquesta espècie és molt característic per estar xapat en dos o haver dos anells a alçades diferents, fet que pot confondre amb la presència de volva. Les espores són de color marró en massa i subgloboses, de 5-6,5 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      